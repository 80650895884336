import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import Select from 'react-select';

export type SelectType = {
    value: string;
    label: string;
};

export type OptionState = {
    isFocused: boolean;
};

export type SelectProps = HTMLAttributes<HTMLDivElement> & {
    options: SelectType[];
    value: SelectType;
    onChangeSelect: (value: SelectType) => void;
};

export const SelectContainer = styled.div`
    width: 18.75rem;
    margin-left: 1.25rem;
    font-family: Eina3-Regular;
    font-size: 1.5rem;
    color: #fff;
`;

const customStyle = {
    option: (provided: Record<string, unknown>, state: OptionState) => ({
        ...provided,
        padding: '1.25rem',
        color: state.isFocused ? '#30241B' : '#fff',
    }),
    menu: (provided: Record<string, unknown>) => ({
        ...provided,
        backgroundColor: '#5C472C',
    }),
    control: () => ({
        width: '18.75rem',
        borderRadius: '0.625rem',
        boxShadow: ' inset 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.4)',
        border: 'none',
        background: '#5c472c',
        height: '3.375rem',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        lineHeight: 2,
    }),
    singleValue: (provided: Record<string, unknown>) => ({
        ...provided,
        color: '#fff',
    }),
    input: (provided: Record<string, unknown>) => ({
        ...provided,
        color: '#fff',
    }),
};

export const AppSelect: FC<SelectProps> = ({
    onChangeSelect,
    value,
    options,
    ...props
}) => {
    return (
        <SelectContainer {...props}>
            <Select
                instanceId={1}
                styles={customStyle}
                getOptionLabel={(select) => select.label}
                getOptionValue={(select) => select.value}
                isClearable={false}
                options={options}
                backspaceRemovesValue={true}
                value={value}
                onChange={(e) => onChangeSelect(e as SelectType)}
            />
        </SelectContainer>
    );
};
