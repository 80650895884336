import { HTMLAttributes, FC } from 'react';
import styled from 'styled-components';

import { Logo } from '../Logo';
import { CommonHeader } from '../CommonHeader';
import { WaitImages } from '../WaitImages';

import imgHeaderBg from '../../assets/images/about-page-header/header-bg@2x.png';
import imgButtonClose from '../../assets/images/about-page-header/btn-close@2x.png';

export type AboutPageProps = HTMLAttributes<HTMLDivElement> & {
    onClose: () => void;
};

export const HeaderContainer = styled(WaitImages)`
    position: relative;
    background-image: url(${imgHeaderBg});
    background-size: contain;
    background-repeat: no-repeat;
    height: 11.875rem;
    width: 37.5rem;
    margin: 0 auto;
`;

export const AboutPageLogo = styled(Logo)`
    top: 5rem;
`;

export const CloseButtonContainer = styled.button`
    position: absolute;
    background: transparent;
    background-image: url(${imgButtonClose});
    background-size: contain;
    background-repeat: no-repeat;
    width: 3.875rem;
    height: 3.75rem;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    top: 19%;
    &:active {
        transform: scale(0.9);
    }
`;

const BG_IMAGES = [imgHeaderBg, imgButtonClose];

export const AboutPageHeader: FC<AboutPageProps> = ({ onClose, ...props }) => (
    <HeaderContainer {...props} images={BG_IMAGES}>
        <CommonHeader onClose={onClose}>
            <AboutPageLogo />
        </CommonHeader>
    </HeaderContainer>
);
