import { createState } from '@hookstate/core';
import { ErrorResponse, GetResponseOptions } from '@parlaygames/fetch';

export type ErrorType = {
    key: string;
    message: string;
    detail: string;
};

export type ErrorFn = () => ErrorType;

export type ErrorState = {
    visible: boolean;
    label: ErrorType['message'];
    message: ErrorType['detail'];
};

export const state = createState({
    visible: false,
    label: '',
    message: '',
} as ErrorState);

const GENERIC_MESSAGE = {
    message: 'Something went wrong',
    detail: "We're having technical issues at the moment. Please try again later.",
};

const getError = (value: Pick<ErrorType, 'message' | 'detail'>) => {
    if (value.message && value.detail) {
        return value;
    } else if (value.message) {
        return {
            message: 'Something went wrong',
            detail: value.message,
        };
    }

    return GENERIC_MESSAGE;
};

export const setError = async (error: unknown) => {
    let value: Pick<ErrorType, 'message' | 'detail'> = GENERIC_MESSAGE;

    if (error instanceof ErrorResponse) {
        value = await (error.response as GetResponseOptions).json();
    } else if (typeof error === 'function') {
        value = (error as ErrorFn)();
    }

    value = getError(value);

    state.label.set(value.message);
    state.message.set(value.detail);
    state.visible.set(true);
};
