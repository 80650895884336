import { useState, useCallback, useEffect, VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import { UnfetchResponse, ErrorResponse } from '@parlaygames/fetch';

import { HomePageReplayerEntry as HomePageReplayerEntryComponent } from '../../components/HomePageReplayerEntry';

import { getGameOrderId } from '../../lib/api';
import { getReplay } from '../../../replayer/lib/api';
import { tryLoadingState } from '../../stores/loader';
import { setError } from '../../stores/error';

const ERRORS = {
    'sale.not.found': {
        message: 'Invalid Ticket Number',
        detail: 'The ticket number entered does not exist. Please try again.',
    },
    'replay.bingo.game.not.complete': {
        message: 'Incomplete Game',
        detail: 'The game is not yet complete for this ticket',
    },
};

export const HomePageReplayerEntry: VFC = () => {
    const [entryCode, setEntryCode] = useState<string[]>([]);

    const navigate = useNavigate();

    const onInfoClick = useCallback(() => navigate('/info'), [navigate]);

    const onEnter = useCallback(
        (ticketNumber: string, isAutomatic = false) =>
            tryLoadingState(async () => {
                try {
                    const gameOrderIds = await getGameOrderId(ticketNumber);

                    await getReplay(gameOrderIds);

                    const params = new URLSearchParams();

                    for (const id of gameOrderIds) {
                        params.append('gameOrderId', id.toString());
                    }

                    if (isAutomatic) {
                        params.set('automatic', 'true');
                    }

                    const url = '/replayer.html?' + params.toString();

                    if (process.env.STORYBOOK) {
                        console.log('redirecting to...', url);
                    } else {
                        window.location.href = url;
                    }
                } catch (e) {
                    const error = e as ErrorResponse<UnfetchResponse>;

                    const status = error.response?.status;

                    if (status === 422) {
                        const { key }: { key: string } =
                            await error.response.json();

                        const details = ERRORS[key as keyof typeof ERRORS];

                        if (details) {
                            setError(() => details);
                            return;
                        }
                    }

                    setError(error);
                }
            }),
        []
    );

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const ticketNumber = params.get('ticketOrderId');

        if (ticketNumber) {
            setEntryCode(ticketNumber.replace(/-/, '').split(''));
            onEnter(ticketNumber, true);
        }
    }, []);

    return (
        <HomePageReplayerEntryComponent
            onInfoClick={onInfoClick}
            onEnter={onEnter}
            entryCode={entryCode}
        />
    );
};
