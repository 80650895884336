import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { CommonHeader } from '../CommonHeader';

import { WaitImages } from '../WaitImages';

import imgPageWhereTo from '../../assets/images/redeem-page/whereto@2x.png';

export type RedeemHeaderProps = HTMLAttributes<HTMLDivElement> & {
    onClose: () => void;
};

export const RedeemHeaderContainer = styled(CommonHeader)`
    display: grid;
    align-items: center;
    justify-content: center;
    width: 37.5rem;
    margin: 0 auto;
`;

export const RedeemBg = styled(WaitImages)`
    position: relative;
    background-image: url(${imgPageWhereTo});
    background-size: 100% 100%;
    width: 30.125rem;
    height: 9.625rem;
    right: 2%;
`;

export const RedeemLabel = styled.span`
    position: relative;
    font-family: Eina4-SemiBold;
    font-size: 1.875rem;
    text-shadow: 0 0.1875rem 0 rgba(0, 0, 0, 0.5);
    color: #fff96b;
    top: 38%;
    left: 22%;
`;

export const RedeemHeader: FC<RedeemHeaderProps> = ({ onClose, ...props }) => (
    <RedeemHeaderContainer {...props} onClose={onClose}>
        <RedeemBg images={imgPageWhereTo}>
            <RedeemLabel>Where To Play/Redeem</RedeemLabel>
        </RedeemBg>
    </RedeemHeaderContainer>
);
