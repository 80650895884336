import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { WaitImages } from '../WaitImages';

import imgMenuTab from '../../assets/images/menu-button/menu-tab@2x.png';

export type MenuButtonProps = HTMLAttributes<HTMLDivElement>;

export const MenuContainer = styled(WaitImages)`
    background-image: url(${imgMenuTab});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-family: Stone-Sans-Semi;
    color: #fff;
    width: 21.4375rem;
    height: 5.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    text-shadow: 0 0.25rem 0.25rem #000;
    cursor: pointer;
    user-select: none;
    &:active {
        transform: scale(0.95);
    }
`;

export const MenuButton: FC<MenuButtonProps> = ({ ...props }) => (
    <MenuContainer {...props} images={imgMenuTab} />
);
