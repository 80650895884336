import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { WaitImages } from '../WaitImages';

import imgB from '../../assets/images/ball/b-ball@3x.png';
import imgI from '../../assets/images/ball/i-ball@3x.png';
import imgN from '../../assets/images/ball/n-ball@3x.png';
import imgG from '../../assets/images/ball/g-ball@3x.png';
import imgO from '../../assets/images/ball/o-ball@3x.png';

const BG_IMAGES = {
    b: imgB,
    i: imgI,
    n: imgN,
    g: imgG,
    o: imgO,
};

export type BingoType = 'b' | 'i' | 'n' | 'g' | 'o';
export type BallProps = HTMLAttributes<HTMLDivElement> & {
    ballType: BingoType;
    ballNum: number;
};

export const BallContainer = styled(WaitImages)<BallProps>`
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    font-size: 0.6875rem;
    font-family: Eina4-SemiBold;
    color: #000;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: ${(props) => `url(${BG_IMAGES[props.ballType]})`};
    span {
        position: relative;
        top: 0.4375rem;
    }
`;

export const Ball: FC<BallProps> = (props) => (
    <BallContainer {...props} images={BG_IMAGES[props.ballType]}>
        <span>{props.ballNum}</span>
    </BallContainer>
);
