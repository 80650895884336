import { FC, HTMLAttributes } from 'react';
import styled, { keyframes } from 'styled-components';

export type PopUpProps = HTMLAttributes<HTMLDivElement>;

const popUpAnimation = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

export const PopUpContainer = styled.div<PopUpProps>`
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    animation: ${popUpAnimation} 300ms linear;
`;

export const PopUpContents = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PopUp: FC<PopUpProps> = (props) => (
    <PopUpContainer {...props}>
        <PopUpContents>{props.children}</PopUpContents>
    </PopUpContainer>
);
