import { MemoryRouter } from 'react-router-dom';

import { AppRoutes } from './routes';

import { GlobalStyle } from './style';

export const App = () => (
    <MemoryRouter>
        <AppRoutes />
        <GlobalStyle />
    </MemoryRouter>
);
