import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { CommonCloseButton } from '../CommonCloseButton';
import { MenuButton } from '../MenuButton';
import { Logo } from '../Logo';

import { WaitImages } from '../WaitImages';

import imgPreviousGames from '../../assets/images/menu-details/previous-games@2x.png';

export type MenuDetailsProps = HTMLAttributes<HTMLDivElement> & {
    onClose: () => void;
    onClickAbout: () => void;
    onClickRedeem: () => void;
    onClickTerms: () => void;
};

export const MenuDetailsContainer = styled(WaitImages)`
    position: fixed;
    top: 25%;
    background-image: url(${imgPreviousGames});
    background-size: 100% 100%;
    width: 30rem;
    height: 26.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MenuCloseButton = styled(CommonCloseButton)`
    position: absolute;
    z-index: 9;
    right: -1.25rem;
    top: -1rem;
`;

export const MenuPageButton = styled(MenuButton)`
    margin-bottom: 0.625rem;
`;

export const MenuLogo = styled(Logo)`
    position: absolute;
    top: -6.25rem;
`;

export const MenuDetails: FC<MenuDetailsProps> = ({
    onClose,
    onClickAbout,
    onClickRedeem,
    onClickTerms,
    ...props
}) => (
    <MenuDetailsContainer {...props} images={imgPreviousGames}>
        <MenuLogo />
        <div>
            <MenuCloseButton onClose={onClose} />
            <MenuPageButton onClick={onClickAbout}>
                About Bingo Treasures
            </MenuPageButton>
            <MenuPageButton onClick={onClickRedeem}>
                Where To Play
            </MenuPageButton>
            <MenuPageButton onClick={onClickTerms}>
                Terms & Conditions
            </MenuPageButton>
        </div>
    </MenuDetailsContainer>
);
