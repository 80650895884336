import { useNavigate } from 'react-router-dom';
import { MenuDetails as MenuDetailsComponent } from '../../components/MenuDetails';

export const MenuDetails = () => {
    const navigate = useNavigate();

    return (
        <MenuDetailsComponent
            onClose={() => navigate(-1)}
            onClickAbout={() => navigate('/about')}
            onClickTerms={() => navigate('/terms-and-conditions')}
            onClickRedeem={() => navigate('/redeem')}
        />
    );
};
