import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { WaitImages } from '../WaitImages';

import imgButtonLight from '../../assets/images/small-button/light.png';
import imgButtonDark from '../../assets/images/small-button/dark.png';

const BTN_IMAGES = {
    light: imgButtonLight,
    dark: imgButtonDark,
};

const OPTIONS = Object.keys(BTN_IMAGES) as ['light', 'dark'];

const BG_IMAGES = Object.values(BTN_IMAGES);

export type SmallButtonProps = HTMLAttributes<HTMLButtonElement> & {
    buttonType: typeof OPTIONS[number];
    buttonLabel: string;
};

export const SmallButtonContainer = styled(WaitImages)`
    display: inline-block;
`;

export const SmallButtonComponent = styled.button<SmallButtonProps>`
    background-image: ${(props) => `url(${BTN_IMAGES[props.buttonType]})`};
    background-size: 100% 100%;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    width: 9.0625rem;
    height: 2.5625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Eina4-SemiBold;
    user-select: none;
    color: ${(props) => (props.buttonType == 'light' ? '#fff667' : '#8e4c08')};
    cursor: pointer;
    &:active {
        transform: scale(0.97);
    }
    transition: 300ms color;
`;

export const SmallButton: FC<SmallButtonProps> = ({ style, ...props }) => (
    <SmallButtonContainer images={BG_IMAGES} style={style}>
        <SmallButtonComponent {...props}>
            <span>{props.buttonLabel}</span>
        </SmallButtonComponent>
    </SmallButtonContainer>
);
