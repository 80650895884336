import { FC } from 'react';
import styled from 'styled-components';

import { WaitImages } from '../WaitImages';

import imgNavLeftBtn from '../../assets/images/recap-nav/left-btn@2x.png';

export type RecapNavProps = {
    previousDisabled?: boolean;
    nextDisabled?: boolean;
    label: string;
    onPrevious: () => void;
    onNext: () => void;
};

export const RecapNavContainer = styled(WaitImages)`
    width: 33.75rem;
    height: 3.625rem;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-content: center;
    align-items: center;
    font-family: Eina3-Regular;
`;

export const LeftNavBtn = styled.div<{ disabled?: boolean }>`
    position: relative;
    background-image: url(${imgNavLeftBtn});
    background-size: 100% 100%;
    height: 3.625rem;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};

    &:active {
        transform: ${(props) => (props.disabled ? 'none' : 'scale(0.9)')};
    }
`;

export const RightNavBtn = styled(LeftNavBtn)`
    transform: rotate(180deg);
    &:active {
        transform: ${(props) =>
            props.disabled ? 'rotate(180deg)' : 'scale(0.9) rotate(180deg)'};
    }
`;

export const DateLabel = styled.div`
    width: 26.25rem;
    height: 3.25rem;
    text-align: center;
    border-top: 0.1875rem solid #493117;
    border-bottom: 0.1875rem solid #493117;
    background-color: #71552f;
    font-size: 1.875rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`;

export const RecapNav: FC<RecapNavProps> = ({
    previousDisabled,
    nextDisabled,
    label,
    onPrevious,
    onNext,
    ...props
}) => (
    <RecapNavContainer {...props} images={imgNavLeftBtn}>
        <LeftNavBtn
            onClick={previousDisabled ? undefined : onPrevious}
            disabled={previousDisabled}
        />
        <DateLabel>
            <span>{label}</span>
        </DateLabel>
        <RightNavBtn
            onClick={nextDisabled ? undefined : onNext}
            disabled={nextDisabled}
        />
    </RecapNavContainer>
);
