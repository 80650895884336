import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { CardSquare } from '../CardSquare';

import { WaitImages } from '../WaitImages';

import imgWinningCardHeader from '../../assets/images/card/winningcard-header@3x.png';

const MIDDLE = 12;
const ARRAY = Array(5)
    .fill(0)
    .map((_v, i) => i);

export type WinningCardProps = HTMLAttributes<HTMLDivElement> & {
    cardId: number;
    tiles: number[];
    soldAt: string;
};

export const WinningCardContainer = styled(WaitImages)`
    width: 13.5625rem;
    height: 15.75rem;
    text-align: center;
    display: grid;
    grid-template-rows: auto 1fr 1fr;
    justify-content: center;
    align-items: center;
    padding-bottom: 5rem;
`;

export const WinningCardIdContainer = styled.div`
    width: 13.5625rem;
    height: 3.125rem;
`;

export const WinningCardIdLabel = styled.h1`
    font-family: Eina4-SemiBold;
    font-size: 0.75rem;
    color: #d7bf70;
`;

export const WinningCardId = styled.div`
    font-family: Eina4-SemiBold;
    font-size: 1.3125rem;
    line-height: 0.2;
    color: #fff;
`;

export const BingoBar = styled.div`
    background-image: url(${imgWinningCardHeader});
    background-size: 100% 100%;
    height: 3.25rem;
`;

export const CardContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    width: 12.5rem;
    left: 0.625rem;
    height: 12.5rem;
`;

export const CardRow = styled.div`
    display: grid;
    grid-template-rows: repeat(5, 1fr);
`;

export const CardSoldAt = styled.div`
    font-family: Eina4-SemiBold;
    color: white;
    font-size: 0.75rem;
    display: inline-block;
`;

export const PATTERN = [0, 4, 6, 8, 16, 18, 20, 24];

export const WinningCard: FC<WinningCardProps> = ({
    cardId,
    tiles,
    soldAt,
    ...props
}) => {
    return (
        <WinningCardContainer {...props} images={imgWinningCardHeader}>
            <WinningCardIdContainer>
                <WinningCardIdLabel>Winning Card ID:</WinningCardIdLabel>
                <WinningCardId>{cardId}</WinningCardId>
            </WinningCardIdContainer>
            <BingoBar />
            <CardContainer>
                {ARRAY.map((ri) => (
                    <CardRow key={ri}>
                        {ARRAY.map((ci) => {
                            const index = ri * ARRAY.length + ci;
                            return index !== MIDDLE ? (
                                <CardSquare
                                    tileType={
                                        PATTERN.includes(index)
                                            ? 'purple'
                                            : 'white'
                                    }
                                    tileNum={tiles[index]}
                                    key={tiles[index]}
                                />
                            ) : (
                                <CardSquare
                                    tileType="treasure"
                                    key={tiles[index]}
                                />
                            );
                        })}
                    </CardRow>
                ))}
            </CardContainer>
            <WinningCardIdLabel>
                Sold At: <CardSoldAt>{soldAt}</CardSoldAt>
            </WinningCardIdLabel>
        </WinningCardContainer>
    );
};
