import { Routes, Route } from 'react-router-dom';

import { PopUp } from './components/PopUp';
import { Redeem } from './containers/Redeem';
import { InfoDetails } from './containers/InfoDetails';
import { MenuDetails } from './containers/MenuDetails';
import { About } from './containers/About';
import { TermsAndConditions } from './containers/TermsAndConditions';
import { Recap } from './containers/Recap';

import { Layout } from './layout';

export const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<Layout />}>
            <Route
                path="redeem"
                element={
                    <PopUp>
                        <Redeem />
                    </PopUp>
                }
            />

            <Route
                path="info"
                element={
                    <PopUp>
                        <InfoDetails />
                    </PopUp>
                }
            />

            <Route
                path="menu"
                element={
                    <PopUp>
                        <MenuDetails />
                    </PopUp>
                }
            />

            <Route
                path="about"
                element={
                    <PopUp>
                        <About />
                    </PopUp>
                }
            />

            <Route
                path="terms-and-conditions"
                element={
                    <PopUp>
                        <TermsAndConditions />
                    </PopUp>
                }
            />

            <Route
                path="recap/:cursor"
                element={
                    <PopUp>
                        <Recap />
                    </PopUp>
                }
            />

            <Route
                path="recap-order/:orderId"
                element={
                    <PopUp>
                        <Recap />
                    </PopUp>
                }
            />
        </Route>
    </Routes>
);
