import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { WaitImages } from '../WaitImages';

import imgCardPurple from '../../assets/images/card/purple.png';
import imgCardWhite from '../../assets/images/card/white.png';
import imgCardTreasure from '../../assets/images/card/treasure.png';

const BG_IMAGES = {
    purple: imgCardPurple,
    white: imgCardWhite,
    treasure: imgCardTreasure,
};

export type CardSquareContainerProps = {
    tileType: 'purple' | 'white' | 'treasure';
    tileNum?: number;
};

export type CardSquareProps = HTMLAttributes<HTMLDivElement> &
    CardSquareContainerProps;

export const CardSquareContainer = styled(WaitImages)<CardSquareContainerProps>`
    display: grid;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    color: #fff;
    font-family: Roboto;
    font-weight: 400;
    font-size: 1.25rem;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
    background-image: ${(props) => `url(${BG_IMAGES[props.tileType]});`};
    color: ${(props) => (props.tileType === 'white' ? '#000' : '#fff')};
    ${({ tileType }) =>
        tileType === 'treasure' ? `span { visibility: hidden };` : ``}
`;

export const CardSquare: FC<CardSquareProps> = (props) => (
    <CardSquareContainer {...props} images={BG_IMAGES[props.tileType]}>
        <span>{props.tileNum}</span>
    </CardSquareContainer>
);
