import { useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from '@hookstate/core';
import { Redeem as RedeemComponent } from '../../components/Redeem';
import { state, fetchRedeemStates } from '../../stores/redeem';
import useScrollTop from '../../hooks/use-scroll-top';

export const Redeem = () => {
    const navigate = useRef(useNavigate());
    const states = useState(state);

    const onClose = useCallback(() => {
        const params = new URLSearchParams(location.search.slice(1));
        console.log(params.get('pathname'));
        if (params.get('pathname') === '/redeem') {
            location.href = '/';
        } else {
            navigate.current(-1);
        }
    }, []);

    useScrollTop();

    useEffect(() => {
        fetchRedeemStates();
    }, []);

    return <RedeemComponent states={states.value} onClose={onClose} />;
};
