import { bingoFetch } from '../../website/lib/api';
import { GameDataResponse } from '../types';

export const getReplay = (gameOrderIds: number[]) =>
    bingoFetch<GameDataResponse>('/v1/treasures/replay', {
        method: 'POST',
        body: JSON.stringify(gameOrderIds),
        headers: {
            'Content-Type': 'application/json',
        },
    });
