import { FC, HTMLAttributes, ReactNode } from 'react';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';

import { WaitImages } from '../WaitImages';

import imgArrowExpanded from '../../assets/images/accordion/arrow-expanded.png';
import imgArrowCollapsed from '../../assets/images/accordion/arrow-collapsed.png';

export type AccordionItem = {
    title: string;
    contents: ReactNode;
};

export type AccordionProps = HTMLAttributes<HTMLDivElement> & {
    items: AccordionItem[];
};

export const AccordionItem = styled.div`
    margin-bottom: 0.875rem;

    .accordion-arrow {
        display: inline-block;
        background-repeat: no-repeat;
    }

    .is-open .accordion-arrow {
        background-image: url(${imgArrowExpanded});
        width: 1.275rem;
        height: 1rem;
        background-size: 1.275rem 1rem;
    }

    .is-closed .accordion-arrow {
        background-image: url(${imgArrowCollapsed});
        width: 1rem;
        height: 1.29375rem;
        background-size: 1rem 1.29375rem;
    }

    p {
        margin: 0;
    }
`;

export const AccordionDetail = styled.div`
    padding: 1rem 1rem 0.8125rem 1.25rem;
    position: relative;
    background-color: rgba(244, 200, 124, 0.1);
`;

export const AccordionHeader = styled(AccordionDetail)`
    cursor: pointer;
    position: relative;
    color: #fffb9e;
    font-size: 1.75rem;
    font-family: Eina4-Bold;
    font-weight: bold;
    text-shadow: 0 0.1875rem 0 rgba(0, 0, 0, 0.5);
    padding: 1rem 1rem 0.8125rem 1.25rem;
`;

export const AccordionContents = styled(AccordionDetail)`
    padding: 0.1rem 1rem 0.8125rem 1.25rem;
    color: white;
    font-family: Eina2-Regular;
    line-height: 1.5rem;
`;

export const AccordionArrow = styled.div`
    position: absolute;
    top: 0;
    right: 1rem;
    height: 100%;
    width: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const BG_IMAGES = [imgArrowExpanded, imgArrowCollapsed];

export const Accordion: FC<AccordionProps> = ({ items, ...props }) => (
    <WaitImages {...props} images={BG_IMAGES}>
        {items.map((item, index) => (
            <AccordionItem key={index}>
                <Collapsible
                    trigger={
                        <AccordionHeader>
                            {item.title}
                            <AccordionArrow>
                                <span className="accordion-arrow" />
                            </AccordionArrow>
                        </AccordionHeader>
                    }
                >
                    <AccordionContents> {item.contents} </AccordionContents>
                </Collapsible>
            </AccordionItem>
        ))}
    </WaitImages>
);
