import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { createTiles } from '@parlaygames/bingo-utility';
import { CallBoardSquare } from '../CallBoardSquare';

import imgCallBoardPanel from '../../assets/images/callboard/panel.png';

import { WaitImages } from '../WaitImages';

export type CallBoardProps = HTMLAttributes<HTMLDivElement> & {
    called: number[];
};

export const CallBoardContainer = styled(WaitImages)`
    background-image: url(${imgCallBoardPanel});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80.9375rem 38.25rem;
    width: 80.9375rem;
    height: 38.25rem;
    transform: scale(0.5);
    transform-origin: top left;
`;

export const SquareContainer = styled.div`
    padding-top: 7.5rem;
    padding-left: 8.5625rem;
`;

export const Square = styled(CallBoardSquare)`
    margin-bottom: 0.3125rem;
`;

export const TILES = createTiles(1, 75, 15, false);

export const CallBoard: FC<CallBoardProps> = ({ called, ...props }) => {
    return (
        <CallBoardContainer {...props} images={imgCallBoardPanel}>
            <SquareContainer>
                {TILES.map((tiles, index) => (
                    <div key={index}>
                        {tiles.map((tile) => (
                            <Square called={called.includes(tile)} key={tile}>
                                {tile}
                            </Square>
                        ))}
                    </div>
                ))}
            </SquareContainer>
        </CallBoardContainer>
    );
};
