import { FC, HTMLAttributes } from 'react';

import styled from 'styled-components';

import useWaitImages from '../../hooks/use-wait-images';

export type WaitImagesProps = HTMLAttributes<HTMLDivElement> & {
    images: string[] | string;
    delay?: number;
};

export const WaitImagesContainer = styled.div<{ isReady: boolean }>`
    opacity: ${(props) => (props.isReady ? 1 : 0)};
    transition: opacity 500ms linear;
`;

export const WaitImages: FC<WaitImagesProps> = ({
    images,
    delay,
    ...props
}) => {
    const [isReady] = useWaitImages(images, delay);

    return <WaitImagesContainer {...props} isReady={isReady} />;
};
