import { FC } from 'react';
import { useState } from '@hookstate/core';

import {
    Loader as LoaderComponent,
    LoaderProps as LoaderComponentProps,
} from '../../components/Loader';
import { state, LoaderStateKeys } from '../../stores/loader';

export type LoaderProps = Omit<LoaderComponentProps, 'visible'> & {
    loaderKeys: LoaderStateKeys[];
};

export const Loader: FC<LoaderProps> = ({ loaderKeys, ...props }) => {
    const loaderState = useState(state);

    const isVisible = loaderKeys.reduce(
        (result, key) => result || loaderState[key].value > 0,
        false
    );

    return <LoaderComponent {...props} visible={isVisible} />;
};
