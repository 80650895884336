import { VFC } from 'react';
import { useState } from '@hookstate/core';

import { state } from '../../stores/error';

import { ErrorPopup as ErrorPopupComponent } from '../../components/ErrorPopup';

export const ErrorPopup: VFC = () => {
    const error = useState(state);

    if (!error.visible.value) {
        return null;
    }

    return (
        <ErrorPopupComponent
            onBack={() => {
                state.visible.set(false);
            }}
            label={error.label.value}
            message={error.message.value}
        />
    );
};
