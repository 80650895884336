import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from '@hookstate/core';

import { parseToReact } from '../../lib/contentful';
import { About as AboutComponent } from '../../components/About';
import { state, fetchAboutPageAccordion } from '../../stores/home';
import useScrollTop from '../../hooks/use-scroll-top';

export const About = () => {
    const navigate = useNavigate();
    const { aboutAccordionItems } = useState(state);

    const aboutPageItems = useMemo(() => {
        return aboutAccordionItems.value.map(({ title, contents }) => ({
            title,
            contents: parseToReact(contents),
        }));
    }, [aboutAccordionItems.value]);

    useScrollTop();

    useEffect(() => {
        fetchAboutPageAccordion();
    }, []);

    return (
        <AboutComponent
            onClose={() => navigate(-1)}
            aboutPageItems={aboutPageItems}
        />
    );
};
