import { HTMLAttributes, FC } from 'react';
import styled, { css } from 'styled-components';

export const BIG_BUTTON_COLORS = {
    blue: css`
        box-shadow: inset 0 0.1875rem 0 0 #3999ff,
            inset 0 -0.1875rem 0 0 #07388f;
        background-image: linear-gradient(to bottom, #126fd4, #0b48ae);
    `,
    green: css`
        box-shadow: inset 0 0.1875rem 0 0 #9ff582,
            inset 0 -0.1875rem 0 0 #078f1f;
        background-image: linear-gradient(to bottom, #43d412, #2aae0b);
    `,
    grey: css`
        box-shadow: inset 0 0.1875rem 0 0 #c0c0c0,
            inset 0 -0.1875rem 0 0 #545454;
        background-image: linear-gradient(to bottom, #c0c0c0, #545454);
    `,
};

export type BigButtonContainerProps = {
    colorType: keyof typeof BIG_BUTTON_COLORS;
};

export type BigButtonProps = HTMLAttributes<HTMLButtonElement> &
    BigButtonContainerProps;

export const BigButtonContainer = styled.button<BigButtonContainerProps>`
    border-radius: 2.5625rem;
    border: 0.1875rem solid #ffe9ac;
    color: white;
    cursor: pointer;
    font-family: Eina4-SemiBold;
    font-size: 1.375rem;
    font-weight: bold;
    height: 2.5625rem;
    width: 11.125rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    white-space: nowrap;
    ${({ colorType }) => BIG_BUTTON_COLORS[colorType]}
    transition: all 300ms;
    &:active {
        transform: scale(0.95);
    }
`;

export const BigButton: FC<BigButtonProps> = (props) => (
    <BigButtonContainer type="button" {...props} />
);
