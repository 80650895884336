import { useCallback } from 'react';
import { useState } from '@hookstate/core';
import { useNavigate } from 'react-router-dom';

import { TermsAndConditions as TermsAndConditionsComponent } from '../../components/TermsAndConditions';

import { parseToReact } from '../../lib/contentful';

import useScrollTop from '../../hooks/use-scroll-top';

import { state } from '../../stores/home';

export const TermsAndConditions = () => {
    const navigate = useNavigate();

    const { termsAndConditions } = useState(state);

    const onClose = useCallback(() => navigate(-1), [navigate]);

    useScrollTop();

    return (
        <TermsAndConditionsComponent onClose={onClose}>
            {parseToReact(termsAndConditions.value)}
        </TermsAndConditionsComponent>
    );
};
