import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { AboutPageHeader } from '../AboutPageHeader';
import { Accordion, AccordionItem } from '../Accordion';
import { PageContainer } from '../PageContainer';

export type AboutProps = HTMLAttributes<HTMLDivElement> & {
    onClose: () => void;
    aboutPageItems: AccordionItem[];
};

export const AccordionContainer = styled.div`
    margin: 5rem 3rem;
`;

export const About: FC<AboutProps> = ({
    aboutPageItems,
    onClose,
    ...props
}) => {
    return (
        <PageContainer type="modal" {...props}>
            <AboutPageHeader onClose={onClose} />
            <AccordionContainer>
                <Accordion items={aboutPageItems} />
            </AccordionContainer>
        </PageContainer>
    );
};
