import { useState } from '@hookstate/core';
import { useNavigate } from 'react-router-dom';

import { InfoDetails as InfoDetailsComponent } from '../../components/InfoDetails';

import { state } from '../../stores/home';

export const InfoDetails = () => {
    const navigate = useNavigate();
    const { ticketDetails } = useState(state);

    if (!ticketDetails.value) {
        return null;
    }

    return (
        <InfoDetailsComponent
            onClose={() => navigate(-1)}
            imageUrl={ticketDetails.value}
        />
    );
};
