import { HTMLAttributes, FC } from 'react';
import styled from 'styled-components';

import { Ball, BallProps } from '../Ball';

import { WaitImages } from '../WaitImages';

import imgBallCallPanel from '../../assets/images/ball-call-board/ballcall-panel.png';

export type BallCallBoardProps = HTMLAttributes<HTMLDivElement> & {
    ballCalls: BallProps[];
};

export const BallCallBoardContainer = styled(WaitImages)`
    background-image: url(${imgBallCallPanel});
    background-size: 100% 100%;
    width: 32.1875rem;
    height: 15.375rem;
`;

export const BallContainer = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 28.75rem;
    top: 20%;
    left: 6%;
`;

export const BallCallBoard: FC<BallCallBoardProps> = ({
    ballCalls,
    ...props
}) => (
    <BallCallBoardContainer {...props} images={imgBallCallPanel}>
        <BallContainer>
            {ballCalls.map((ball, i) => (
                <Ball key={i} ballType={ball.ballType} ballNum={ball.ballNum} />
            ))}
        </BallContainer>
    </BallCallBoardContainer>
);
