import { HTMLAttributes, FC } from 'react';
import styled from 'styled-components';

import { WaitImages } from '../WaitImages';

import imgBtnClose from '../../assets/images/about-page-header/btn-close@2x.png';

export type CloseButtonContainerProps = HTMLAttributes<HTMLButtonElement> & {
    onClose: () => void;
};

export const CloseButtonContainer = styled(WaitImages)`
    display: inline-block;
`;

export const CloseButton = styled.button`
    position: absolute;
    background: transparent;
    background-image: url(${imgBtnClose});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 3.875rem;
    height: 3.75rem;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    &:active {
        transform: scale(0.9);
    }
`;

export const CommonCloseButton: FC<CloseButtonContainerProps> = ({
    onClose,
    style,
    ...props
}) => (
    <CloseButtonContainer style={style} images={imgBtnClose}>
        <CloseButton {...props} onClick={onClose} />
    </CloseButtonContainer>
);
