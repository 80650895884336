import { FC, HTMLAttributes, UIEvent, useCallback } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import { WaitImages } from '../WaitImages';

import imgRecapListPastDraws from '../../assets/images/recap-list/past-draws@2x.png';

export type RecapListType = {
    id: number;
    date: string;
    winAmount: string;
    winBalls: number;
};

export type RecapListProps = HTMLAttributes<HTMLDivElement> & {
    lists: RecapListType[];
    onRecap: (list: RecapListType, index: number) => void;
    onScrollTop: () => void;
    onScrollBottom: () => void;
};

export const RecapListContainer = styled(WaitImages)`
    background-image: url(${imgRecapListPastDraws});
    background-size: cover;
    background-repeat: no-repeat;
    width: 33.125rem;
    height: 15.25rem;
    margin: 0 auto;
    position: relative;
    transition: opacity 0.5s linear;

    @media only screen and (max-width: 630px) {
        transform: scale(1.1);
    }
`;

export const RecapLabel = styled.h1`
    position: relative;
    text-shadow: 0 0.125rem 0 rgba(0, 0, 0, 0.5);
    font-family: Eina4-SemiBold;
    font-size: 1.3125rem;
    color: #fff96b;
    top: 10.5%;
    left: 34.5%;
`;

export const ListContainer = styled.div`
    position: relative;
    top: 11.5%;
    left: 14%;
    color: #fff;
    width: 24.0625rem;
    font-family: Eina4-SemiBold;
    ul {
        margin-top: 30rem;
        list-style: none;
        line-height: 1.6;
        height: 6.25rem;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 1.5625rem;
        padding: 0;
        &::-webkit-scrollbar {
            width: 0.625rem;
        }

        &::-webkit-scrollbar-track {
            background-color: darkgrey;
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
        }
    }
    li {
        cursor: pointer;
        font-size: 0.9375rem;
    }

    @media only screen and (max-width: 630px) {
        ul {
            margin: 1.2rem 0.5rem 0 0;
            height: 5rem;
            padding: 0.25rem 1.5625rem 1.5625rem 1.5625rem;
        }
        li {
            padding-top: 0.33rem;
            padding-bottom: 0.33rem;
            font-size: 0.95rem;
        }
    }
`;

export const RecapList: FC<RecapListProps> = ({
    lists,
    onRecap,
    onScrollTop,
    onScrollBottom,
    children,
    ...props
}) => {
    const onScroll = useDebouncedCallback(
        useCallback(
            (e: UIEvent<HTMLUListElement>) => {
                const target = e.target as HTMLUListElement;

                const position = Math.ceil(
                    target.offsetHeight + target.scrollTop
                );

                if (position === target.offsetHeight) {
                    onScrollTop();
                } else if (position >= target.scrollHeight) {
                    onScrollBottom();
                }
            },
            [onScrollTop, onScrollBottom]
        ),
        250
    );

    return (
        <RecapListContainer {...props} images={imgRecapListPastDraws}>
            <RecapLabel>PAST DRAWS & WINNERS</RecapLabel>
            <ListContainer>
                <ul onScroll={onScroll}>
                    {lists.map((list, i) => {
                        return (
                            <li key={i} onClick={() => onRecap(list, i)}>
                                {list.date} - {list.winAmount} - {list.winBalls}{' '}
                                balls
                            </li>
                        );
                    })}
                </ul>
            </ListContainer>
            {children}
        </RecapListContainer>
    );
};
