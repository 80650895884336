import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { createClient, Entry, EntryFields, Asset } from 'contentful';
import { AccordionItemType, ContentfulHomePage, LocationType } from '../types';
import { getConfig } from './api';

export const parseToReact = (richText: EntryFields.RichText) =>
    documentToReactComponents(richText as Document, {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const { url } = (node.data.target as Asset).fields.file;
                return <img src={url} />;
            },
        },
    });

export let client = createClient({
    space: 'not-specified',
    environment: 'master',
    accessToken: 'not-specified',
});

export const initialize = async () => {
    const config = await getConfig();

    client = createClient({
        space: config.CONTENTFUL_MARKETING_SPACE_ID,
        environment: config.CONTENTFUL_MARKETING_ENV_ID,
        accessToken: config.CONTENTFUL_MARKETING_TOKEN,
    });
};

export const getAboutPageAccordionItems = async () => {
    const {
        items: [
            {
                fields: { accordionCollection },
            },
        ],
    } = await client.getEntries<{
        accordionCollection: Entry<AccordionItemType>[];
    }>({
        content_type: 'aboutPage',
        select: 'fields.accordionCollection',
    });

    return accordionCollection.map((item) => ({
        title: item.fields.title,
        contents: item.fields.contents,
    }));
};

export const getHomePageDetails = async () => {
    const {
        items: [{ fields }],
    } = await client.getEntries<ContentfulHomePage>({
        content_type: 'homePage',
    });

    const banners = fields.banners.map((banner) => banner.fields.file.url);
    const ticketDetails = fields.ticketDetails.fields.file.url;
    const termsAndConditions = fields.termsAndConditions;

    return {
        banners,
        ticketDetails,
        termsAndConditions,
    };
};

export const getRedeemDetails = async () => {
    const { items } = await client.getEntries<LocationType>({
        content_type: 'playRedeemLocation',
    });

    return items.map((item) => item.fields);
};
