import { FC } from 'react';
import styled from 'styled-components';

import { Ball, BingoType } from '../Ball';

export type WinBallType = {
    ballType: BingoType;
    ballNum: number;
};
export type RecapDetailsProps = {
    gameTime: string;
    gameID: string;
    numWinners: string;
    amountWon: string;
    winBall: WinBallType;
    winCall: number;
};

export const RecapDetailsContainer = styled.div`
    font-family: Eina4-SemiBold;
    font-size: 0.875rem;
    width: 100%;
`;

export const RecapTable = styled.table`
    border-spacing: 0.625rem;
    width: 100%;
`;

export const RecapLabelTd = styled.td`
    color: #d7bf70;
    text-align: right;

    width: 25%;

    &:first-child {
        width: 35%;
    }
`;

export const RecapDetailTd = styled.td`
    color: #fff;
    width: 7.375rem;
`;

export const RecapDetails: FC<RecapDetailsProps> = ({
    gameID,
    gameTime,
    numWinners,
    amountWon,
    winBall,
    winCall,
    ...props
}) => (
    <RecapDetailsContainer {...props}>
        <RecapTable>
            <tbody>
                <tr>
                    <RecapLabelTd>Game Time:</RecapLabelTd>
                    <RecapDetailTd style={{ whiteSpace: 'nowrap' }}>
                        {gameTime}
                    </RecapDetailTd>
                    <RecapLabelTd>Game ID:</RecapLabelTd>
                    <RecapDetailTd>{gameID}</RecapDetailTd>
                </tr>
                <tr>
                    <RecapLabelTd>No. of Winner(s):</RecapLabelTd>
                    <RecapDetailTd>{numWinners}</RecapDetailTd>
                    <RecapLabelTd>Winning Ball:</RecapLabelTd>
                    <RecapDetailTd>
                        <Ball
                            ballType={winBall.ballType}
                            ballNum={winBall.ballNum}
                        />
                    </RecapDetailTd>
                </tr>
                <tr>
                    <RecapLabelTd>Amount Won:</RecapLabelTd>
                    <RecapDetailTd>{amountWon}</RecapDetailTd>
                    <RecapLabelTd>Winning Call:</RecapLabelTd>
                    <RecapDetailTd>{winCall}</RecapDetailTd>
                </tr>
            </tbody>
        </RecapTable>
    </RecapDetailsContainer>
);
