import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

export type BannerProps = HTMLAttributes<HTMLDivElement> & {
    imageUrl: string;
};

export const BannerContainer = styled.div<BannerProps>`
    background-image: ${({ imageUrl }) => `url(${imageUrl});`};
    background-size: 100% 100%;
    width: 10.625rem;
    height: 17rem;
    cursor: pointer;
`;

export const Banner: FC<BannerProps> = (props) => (
    <BannerContainer {...props} />
);
