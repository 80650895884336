import { createState } from '@hookstate/core';
import { EntryFields } from 'contentful';
import * as CONTENTFUL from '../lib/contentful';
import { AccordionItemType } from '../types';
import { createStateRequest } from './loader';

export type HomeState = {
    termsAndConditions: EntryFields.RichText;
    aboutAccordionItems: AccordionItemType[];
    ticketDetails: string;
    banners: string[];
};

const termsAndConditions: EntryFields.RichText = {
    nodeType: 'document',
    data: {},
    content: [
        {
            nodeType: 'paragraph',
            data: {},
            marks: [],
            content: [],
        },
    ],
};

export const state = createState({
    termsAndConditions,
    aboutAccordionItems: [],
    ticketDetails: '',
    banners: [],
    requests: 0,
} as HomeState);

export const fetchHomeDetails = createStateRequest(async () => {
    const homeDetails = await CONTENTFUL.getHomePageDetails();

    state.termsAndConditions.set(homeDetails.termsAndConditions);
    state.ticketDetails.set(homeDetails.ticketDetails);
    state.banners.set(homeDetails.banners);
});

export const fetchAboutPageAccordion = createStateRequest(async () => {
    const aboutAccordionItems = await CONTENTFUL.getAboutPageAccordionItems();

    state.aboutAccordionItems.set(aboutAccordionItems);
});
