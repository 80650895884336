import { createFetch } from '@parlaygames/fetch';
import { PastDraw, NextDraw, RecapDetails } from '../types';
import { stringify } from 'querystringify';

export type Config = {
    CONTENTFUL_MARKETING_SPACE_ID: string;
    CONTENTFUL_MARKETING_ENV_ID: string;
    CONTENTFUL_MARKETING_TOKEN: string;
};

export type PastDrawQueryParams = {
    page: number;
    size: number;
    fromDateTime?: string;
};

export const { fetch: bingoFetch } = createFetch({
    baseURL: '/bingo',
});

export const { fetch: posFetch } = createFetch({
    baseURL: '/pos-api',
});

const { fetch: configFetch } = createFetch({});

const formatTicketNumber = (ticketNumber: string) => {
    if (ticketNumber.includes('-')) {
        return ticketNumber;
    }
    return ticketNumber.slice(0, 4) + '-' + ticketNumber.slice(4);
};

export const getPastDraws = (query: PastDrawQueryParams) =>
    bingoFetch<PastDraw[]>('/v1/recap?' + stringify(query));

export const getRecapDetails = (gameSessionId: number | string) =>
    bingoFetch<RecapDetails>('/v1/recap/' + gameSessionId);

export const getNextGameDate = () => bingoFetch<NextDraw>('/v1/treasures/draw');

export const getGameOrderId = (ticketNumber: string) =>
    posFetch<number[]>(
        '/purchases/ids?ticketNumber=' + formatTicketNumber(ticketNumber),
        {
            method: 'POST',
            body: JSON.stringify({ type: 'treasures' }),
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

export const getConfig = () => configFetch<Config>('/site-data.json');
