import { FC, ReactNode, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { BigButton } from '../BigButton';

import { WaitImages } from '../WaitImages';

import imgErrorWood from '../../assets/images/error-popup/wood@2x.png';
import imgErrorWarning from '../../assets/images/error-popup/warning@2x.png';

export type ErrorPopupProps = HTMLAttributes<HTMLDivElement> & {
    label: string | ReactNode;
    message: string | ReactNode;
    onBack: () => void;
};

export const ErrorPopupContainer = styled(WaitImages)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24.9375rem;
    height: 21.875rem;
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    z-index: 9999;
`;

export const ErrorPopupHeader = styled.div`
    background-image: url(${imgErrorWood});
    background-size: 100% 100%;
    height: 3rem;
`;

export const ErrorIcon = styled.div`
    background-image: url(${imgErrorWarning});
    background-size: 100% 100%;
    width: 4.0625rem;
    height: 3.625rem;
    margin: 1.25rem auto;
`;

export const ErrorPopupDetails = styled.div`
    background-color: #fff;
    border-bottom-left-radius: 0.43125rem;
    border-bottom-right-radius: 0.43125rem;
    min-width: 18.75rem;
    height: 18.875rem;
    display: grid;
    grid-template-rows: auto 1fr;
    text-align: center;
    padding: 0.625rem;
`;

export const ErrorMessageContainer = styled.div`
    color: #5a5959;
`;

export const ErrorLabel = styled.div`
    font-family: Eina4-SemiBold;
    font-size: 1.625rem;
`;

export const ErrorMessage = styled.p`
    font-family: Eina2-Regular;
    font-size: 1rem;
    line-height: 1.7;
`;

const BG_IMAGES = [imgErrorWood, imgErrorWarning];

export const ErrorPopup: FC<ErrorPopupProps> = ({
    label,
    message,
    onBack,
    ...props
}) => (
    <ErrorPopupContainer {...props} images={BG_IMAGES}>
        <ErrorPopupHeader />
        <ErrorPopupDetails>
            <ErrorIcon />
            <ErrorMessageContainer>
                <ErrorLabel>{label}</ErrorLabel>
                <ErrorMessage>{message}</ErrorMessage>
                <BigButton colorType="blue" onClick={onBack}>
                    Go Back
                </BigButton>
            </ErrorMessageContainer>
        </ErrorPopupDetails>
    </ErrorPopupContainer>
);
