import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { PageContainer } from '../PageContainer';
import { AboutPageHeader } from '../AboutPageHeader';

export type TermsAndConditionsProps = HTMLAttributes<HTMLDivElement> & {
    onClose: () => void;
};

export const TermsConditionContainer = styled.div`
    padding: 2.5rem 1.25rem;
    font-family: Eina3-Regular;
    font-size: 0.75rem;
    color: #fff;
`;
export const TermsAndConditions: FC<TermsAndConditionsProps> = ({
    onClose,
    children,
    ...props
}) => (
    <PageContainer type="modal" {...props}>
        <AboutPageHeader onClose={onClose} />
        <TermsConditionContainer>{children}</TermsConditionContainer>
    </PageContainer>
);
