import { HTMLAttributes, FC } from 'react';
import styled, { css } from 'styled-components';

export const BUTTON_STYLES = {
    light: css`
        background: #f9b14b;
        background: linear-gradient(
            to bottom,
            #f9b14b 0%,
            #fcb445 50%,
            #fcb445 51%,
            #f99e3c 98%
        );
        width: 12.6875rem;
        height: 2.125rem;
        border: 0.3125rem solid #e4be62;
        cursor: pointer;
        outline: 0.0625rem solid #b99f52;
        font-size: 1.875rem;
        color: #fef667;
    `,
    dark: css`
        background: rgb(212, 181, 86);
        background: linear-gradient(
            to bottom,
            rgba(212, 181, 86, 1) 0%,
            rgba(199, 124, 30, 1) 14%,
            rgba(221, 145, 55, 1) 50%,
            rgba(199, 124, 30, 1) 89%,
            rgba(212, 181, 86, 1) 100%
        );
        font-size: 1.625rem;
        color: #8f4c09;
        width: 13.5rem;
        height: 2.875rem;
    `,
};

export type CommonButtonContainerProps = {
    btnType: keyof typeof BUTTON_STYLES;
};

export type CommonButtonProps = HTMLAttributes<HTMLDivElement> &
    CommonButtonContainerProps & {
        onBtnClick: () => void;
        btnLabel: string;
    };

export const CommonButtonContainer = styled.div<CommonButtonContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ede9bc;
    background: linear-gradient(to bottom, #ede9bc 0%, #cec387 100%);
    width: 13.75rem;
    height: 3.125rem;
    border-radius: 0.25rem;
    outline: 0.0625rem solid #b99f52;
    &:active {
        transform: scale(0.98);
    }
    & > div {
        ${({ btnType }) => BUTTON_STYLES[btnType]}
    }
`;

export const MainButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    cursor: pointer;
`;

export const CommonButtonLabel = styled.div`
    font-family: Eina4-SemiBold;
    user-select: none;
`;

export const CommonButton: FC<CommonButtonProps> = ({
    onBtnClick,
    btnLabel,
    ...props
}) => (
    <CommonButtonContainer {...props}>
        <MainButton onClick={onBtnClick}>
            <CommonButtonLabel>{btnLabel}</CommonButtonLabel>
        </MainButton>
    </CommonButtonContainer>
);
