import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup,
    ButtonBack,
    ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { Banner } from '../Banner';

import { WaitImages } from '../WaitImages';

import imgArrowLeft from '../../assets/images/game-list/arrow-left@2x.png';
import imgArrowRight from '../../assets/images/game-list/arrow-right@2x.png';
import imgBg from '../../assets/images/game-list/BG@2x.png';

export type GameListSliderProps = HTMLAttributes<HTMLDivElement> & {
    slides: string[];
};

export const GameListMainContainer = styled(WaitImages)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const GameListSliderContainer = styled.div`
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    border: solid 0.25rem #795229;
    padding: 0.625rem;
    width: 31.75rem;
    left: 1.875rem;
    z-index: 9;
    margin-bottom: 1.25rem;
    min-height: 18.5rem;
`;

export const NavLeft = styled(ButtonBack)`
    cursor: pointer;
    background-image: url(${imgArrowLeft});
    background-size: 100% 100%;
    width: 2rem;
    height: 3.75rem;
    &:active {
        transform: scale(0.9);
    }
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: transparent;
    position: relative;
    left: -51%;
`;

export const NavRight = styled(ButtonNext)`
    background-image: url(${imgArrowRight});
    background-size: 100% 100%;
    &:active {
        transform: scale(0.9);
    }
    width: 2rem;
    height: 3.75rem;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: transparent;

    position: relative;
    right: -51%;
`;

export const SliderContainer = styled(CarouselProvider)`
    background-image: url(${imgBg});
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: center;
    align-items: center;
    width: 33rem;
    height: 18.5rem;
`;

export const ListSlider = styled(Slider)`
    width: 32rem;
    height: 18.5rem;
    margin-top: 0.8125rem;
`;

export const ListBanner = styled(Banner)`
    width: 100%;
`;

export const ListDotGroup = styled(DotGroup)`
    position: absolute;
    display: flex;
    width: 96%;
    bottom: -1.25rem;
    justify-content: center;
    & > button {
        width: 1.875rem;
        margin-right: 0.625rem;
        height: 0.3125rem;
        border-radius: 0.625rem;
        color: transparent;
        border: none;
    }
    .carousel__dot {
        background: #654e2b;
    }
    .carousel__dot--selected {
        background: #d7bf70;
    }
`;

const BG_IMAGES = [imgArrowLeft, imgArrowRight, imgBg];

export const GameListSlider: FC<GameListSliderProps> = ({
    slides,
    ...props
}) => (
    <GameListMainContainer {...props} images={slides.concat(BG_IMAGES)}>
        <GameListSliderContainer>
            {slides.length > 0 && (
                <SliderContainer
                    naturalSlideWidth={100}
                    naturalSlideHeight={125}
                    visibleSlides={1}
                    totalSlides={slides.length}
                    infinite={true}
                >
                    <NavLeft>
                        <></>
                    </NavLeft>
                    <ListSlider>
                        {slides.map((slide, idx) => (
                            <Slide index={idx} key={slide}>
                                <ListBanner imageUrl={slide} />
                            </Slide>
                        ))}
                    </ListSlider>
                    <NavRight>
                        <></>
                    </NavRight>
                    <ListDotGroup />
                </SliderContainer>
            )}
        </GameListSliderContainer>
    </GameListMainContainer>
);
