import { useState } from '@hookstate/core';
import { formatCurrency } from '@parlaygames/currency';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../components/Loader';
import { RecapList, RecapListType } from '../../components/RecapList';
import { formatDate } from '../../lib/date';
import * as recapStore from '../../stores/recap';

export const PastDrawsLoader = styled(Loader)`
    position: 'absolute';
    top: 9.375rem;
`;

export const PastDraws = () => {
    const navigate = useNavigate();

    const recapState = useState(recapStore.state);

    const lists = useMemo(
        (): RecapListType[] =>
            recapState.pastDraws.value.map((draw) => ({
                id: draw.gameSessionId,
                date: formatDate(draw.gameDate, 'MMMM dd, yyyy'),
                winBalls: draw.totalBallCalls,
                winAmount: formatCurrency(parseFloat(draw.amountWon), {
                    symbol: '$',
                }),
            })),
        [recapState.pastDraws.value]
    );

    const onRecap = useCallback((_item: RecapListType, cursor) => {
        navigate('/recap/' + cursor);
    }, []);

    useEffect(() => {
        recapStore.fetchLatestPastDraws();
    }, []);

    return (
        <RecapList
            lists={lists}
            onScrollTop={recapStore.fetchLatestPastDraws}
            onScrollBottom={recapStore.fetchNextPastDraws}
            onRecap={onRecap}
        >
            <PastDrawsLoader
                visible={recapState.requests.value > 0}
                width={40}
                height={40}
            />
        </RecapList>
    );
};
