import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { WaitImages } from '../WaitImages';

import imgLogo from '../../assets/images/logo/logo@2x.png';

export type LogoProps = HTMLAttributes<HTMLDivElement>;

export const LogoContainer = styled(WaitImages)`
    position: relative;
    display: grid;
    justify-content: center;
    margin: 0 auto;
`;

export const LogoImage = styled.div`
    background-image: url(${imgLogo});
    background-size: 100% 100%;
    height: 9.9375rem;
    width: 17.25rem;
`;

export const Logo: FC<LogoProps> = (props) => (
    <LogoContainer {...props} images={imgLogo}>
        <LogoImage />
    </LogoContainer>
);
