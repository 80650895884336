import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import useWaitImages, { Dimension } from '../../hooks/use-wait-images';

import { CommonCloseButton } from '../CommonCloseButton';

import { WaitImagesContainer } from '../WaitImages';

export type BaseInfoDetailsProps = {
    imageUrl: string;
};

export type InfoDetailsContainerProps = BaseInfoDetailsProps & {
    naturalWidth: number;
    naturalHeight: number;
};

export type InfoDetailsProps = HTMLAttributes<HTMLDivElement> &
    BaseInfoDetailsProps & {
        onClose: () => void;
    };

const RATIO_WIDTH = 320;
const RATIO_HEIGHT = 480;
const RATIO = RATIO_WIDTH / RATIO_HEIGHT;

export const InfoDetailsContainer = styled(
    WaitImagesContainer
)<InfoDetailsContainerProps>`
    background-image: ${(props) => `url(${props.imageUrl})`};
    background-size: 100% 100%;
    position: fixed;
    width: ${(props) => (props.naturalWidth * RATIO) / 16 + 'rem'};
    height: ${(props) => (props.naturalHeight * RATIO) / 16 + 'rem'};
    top: 50%;
    left: 50%;
    border: 5px solid rgb(181 129 40);
    border-radius: 0.375rem;
    transform: translate(-50%, -50%);
    overflow: visible !important;
`;

export const InfoDetailsCloseButton = styled(CommonCloseButton)`
    position: absolute;
    z-index: 9;
    right: -1.9375rem;
    top: -1.75rem;
`;

export const factorDimension = (
    size: number,
    maxSize: number,
    factor: number
) => {
    let current = size;
    while (current > maxSize) {
        current = current * factor;
    }
    return current;
};

const getDimension = (dimensions: Dimension[] | null) => {
    if (!dimensions || dimensions.length === 0) {
        return {
            naturalWidth: RATIO_WIDTH,
            naturalHeight: RATIO_HEIGHT,
        };
    }

    const [dimension] = dimensions;

    return {
        naturalWidth: factorDimension(dimension.width, RATIO_WIDTH * 2, 0.9),
        naturalHeight: factorDimension(dimension.height, RATIO_HEIGHT * 2, 0.9),
    };
};

export const InfoDetails: FC<InfoDetailsProps> = (props) => {
    const [isReady, dimensions] = useWaitImages(props.imageUrl, 0);

    return (
        <InfoDetailsContainer
            {...props}
            {...getDimension(dimensions)}
            isReady={isReady}
        >
            <InfoDetailsCloseButton onClose={props.onClose} />
        </InfoDetailsContainer>
    );
};
