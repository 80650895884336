import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { AppSelect, SelectType } from '../AppSelect';

export type SelectStateEntryProps = HTMLAttributes<HTMLDivElement> & {
    value: SelectType;
    options: SelectType[];
    onChangeText: (value: SelectType) => void;
};

const SelectStateEntryContainer = styled.div`
    display: flex;
    align-items: center;
    font-family: Eina3-Regular;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fff;
    margin-left: 0.625rem;
`;

const SelectStateEntryLabel = styled.div`
    margin-right: 0.625rem;
`;

export const SelectStateEntry: FC<SelectStateEntryProps> = ({
    onChangeText,
    options,
    value,
    ...props
}) => (
    <SelectStateEntryContainer {...props}>
        <SelectStateEntryLabel>Select State:</SelectStateEntryLabel>
        <AppSelect
            value={value}
            options={options}
            onChangeSelect={onChangeText}
        />
    </SelectStateEntryContainer>
);
