import { FC, HTMLAttributes, useMemo, useState } from 'react';
import styled from 'styled-components';
import { SelectType } from '../AppSelect';
import { CommonButton } from '../CommonButton';
import { PageContainer } from '../PageContainer';
import { RedeemHeader } from '../RedeemHeader';
import { SelectStateEntry } from '../SelectStateEntry';
import { StateList } from '../StateList';
import { LocationType } from '../../types';

export type RedeemProps = HTMLAttributes<HTMLDivElement> & {
    states: LocationType[];
    onClose: () => void;
};

export const RedeemButtonsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1.875rem;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;
`;

export const RedeemSearchContainer = styled.div`
    position: relative;
    background-color: #30241b;
    border-radius: 0.625rem;
    margin: 1.25rem 2.5rem;
    padding: 0.625rem;
    height: 100%;
`;

export const RedeemStateListContainer = styled(StateList)`
    margin-top: 1.25rem;
`;

export const ALL_STATES = {
    label: 'All States',
    value: '*',
};

export const PLAY_ONLY_BUTTONS: ['light', 'dark'] = ['light', 'dark'];

export const REDEEM_BUTTONS = PLAY_ONLY_BUTTONS.slice().reverse();

export const Redeem: FC<RedeemProps> = ({
    states: rawStates,
    onClose,
    ...props
}) => {
    const options = useMemo(() => {
        const mapped = rawStates.map(({ state }) => ({
            label: state,
            value: state,
        }));
        return [ALL_STATES].concat(mapped);
    }, [rawStates]);

    const [selectedState, setSelectedState] = useState<SelectType>(ALL_STATES);

    const [filterButtonActive, setFilterButtonActive] = useState(0);

    const states = useMemo(() => {
        const isPlayOnly = filterButtonActive === 0;
        const isAllState = selectedState === ALL_STATES;

        return rawStates.filter(
            (currentState) =>
                (isAllState || currentState.state === selectedState.value) &&
                currentState.isPlayOnly === isPlayOnly
        );
    }, [selectedState, rawStates, filterButtonActive]);

    const [btnPlayOnly, btnRedeem] = filterButtonActive
        ? REDEEM_BUTTONS
        : PLAY_ONLY_BUTTONS;

    return (
        <PageContainer type="modal" {...props}>
            <RedeemHeader onClose={onClose} />
            <RedeemButtonsContainer>
                <CommonButton
                    btnLabel="PLAY ONLY"
                    btnType={btnPlayOnly}
                    onBtnClick={() => setFilterButtonActive(0)}
                />
                <CommonButton
                    btnLabel="REDEEM/PLAY"
                    btnType={btnRedeem}
                    onBtnClick={() => setFilterButtonActive(1)}
                />
            </RedeemButtonsContainer>
            <RedeemSearchContainer>
                <SelectStateEntry
                    options={options}
                    value={selectedState}
                    onChangeText={setSelectedState}
                />
                <RedeemStateListContainer states={states} />
            </RedeemSearchContainer>
        </PageContainer>
    );
};
