import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

export type TimerDetails = {
    days: string;
    hours: string;
    minutes: string;
};

export default function useTimer(dateTime: string, onTimeUp: () => void) {
    const [timeStamp, setTimeStamp] = useState('');
    const [details, setDetails] = useState<TimerDetails>({
        days: '',
        hours: '',
        minutes: '',
    });

    useEffect(() => {
        const next = dayjs(dateTime);

        if (!next.isValid()) {
            return;
        }

        setTimeStamp(next.format('MM/DD/YYYY | hh:mm A'));

        const setDuration = () => {
            const current = dayjs();

            const days = next.diff(current, 'day');
            const hours = next.diff(current, 'hour');
            const minutes = Math.max(next.diff(current, 'minute'), 0);

            setDetails({
                days: days + '',
                hours: (hours % 24) + '',
                minutes: (minutes % 60) + '',
            });

            if (minutes === 0) {
                onTimeUp();
                clearInterval(id);
            }
        };

        const id = setInterval(setDuration, 1000);

        return () => {
            clearInterval(id);
        };
    }, [dateTime, onTimeUp]);

    return {
        timeStamp,
        ...details,
    };
}
