import { useEffect, useMemo, useCallback } from 'react';
import { useState } from '@hookstate/core';
import { useNavigate, useParams, useMatch } from 'react-router-dom';
import { formatCurrency } from '@parlaygames/currency';
import { Recap as RecapComponent } from '../../components/Recap';
import { BallProps } from '../../components/Ball';
import * as recapStore from '../../stores/recap';
import * as loadingStore from '../../stores/loader';
import { RecapWinner } from '../../types';

import useScrollTop from '../../hooks/use-scroll-top';
import { formatDate } from '../../lib/date';

const BINGO = ['b', 'i', 'n', 'g', 'o'];

export const Recap = () => {
    const navigate = useNavigate();
    const match = useMatch('/recap-order/:orderId');
    const orderId = match?.params?.orderId;
    const params = useParams<{ cursor: string }>();
    const cursor = useMemo(
        () => parseInt(params.cursor || '0', 10),
        [params.cursor]
    );

    useScrollTop();

    const recapState = useState(recapStore.state);
    const loadingState = useState(loadingStore.state);

    const onClose = useCallback(() => {
        if (typeof orderId === 'string') {
            location.href = '/';
        } else {
            navigate('/');
        }
    }, [orderId]);

    const recapDetails = useMemo(() => {
        const details = recapState.current.value;

        if (!details) {
            return null;
        }

        const { ballCalls } = details;

        const winningCards = details.gameParts.reduce(
            (result, part) =>
                result.concat(
                    part.winningCards.map((card) => ({
                        ...card,
                        soldAt: card.siteName,
                    }))
                ),
            [] as Array<RecapWinner & { soldAt: string }>
        );

        const numWinners =
            winningCards.length > 1
                ? `${winningCards.length} (Split Win)`
                : '1';

        const lastBall = ballCalls[ballCalls.length - 1];

        const winBall = {
            ballType: BINGO[
                Math.floor((lastBall - 1) / 15)
            ] as BallProps['ballType'],
            ballNum: lastBall,
        } as BallProps;

        const recapPageBallCalls: BallProps[] = ballCalls.map((value) => ({
            ballType: BINGO[
                Math.floor((value - 1) / 15)
            ] as BallProps['ballType'],
            ballNum: value,
        }));

        return {
            navLabel: formatDate(details.gameDate, 'MMMM dd, yyyy'),
            winningCards,
            callBoardCalled: ballCalls,
            recapPageDetails: {
                gameID: details.gameId,
                gameTime: formatDate(details.gameDate, 'hh:mm a ZZZZ'),
                numWinners,
                amountWon: formatCurrency(parseFloat(details.amountWon), {
                    symbol: '$',
                }),
                winBall,
                winCall: ballCalls.length,
            },
            recapPageBallCalls,
        };
    }, [recapState.current]);

    useEffect(() => {
        if (typeof orderId === 'string') {
            recapStore.fetchRecapDetail(orderId);
        } else {
            recapStore.fetchRecapDetails(cursor);
        }
    }, [cursor, orderId]);

    if (!recapDetails) {
        return null;
    }

    return (
        <RecapComponent
            hasNavigation={typeof orderId !== 'string'}
            navLabel={recapDetails.navLabel}
            winningCards={recapDetails.winningCards}
            callBoardCalled={recapDetails.callBoardCalled}
            recapPageBallCalls={recapDetails.recapPageBallCalls}
            recapPageDetails={recapDetails.recapPageDetails}
            loading={loadingState.main.value > 0}
            nextDisabled={cursor === 0}
            onPrevious={() => navigate('/recap/' + (cursor + 1))}
            onNext={() => navigate('/recap/' + (cursor - 1))}
            onClose={onClose}
        />
    );
};
