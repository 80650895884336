import { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { HallType, LocationType } from '../../types';

import { WaitImages } from '../WaitImages';

import imgGetDirections from '../../assets/images/state-list/get-directions@2x.png';

export type StateListProps = HTMLAttributes<HTMLDivElement> & {
    states: LocationType[];
};

const FLEX_ROW = css`
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-between;
`;

const StateListContainer = styled(WaitImages)`
    display: flex;
    flex-direction: column;
`;
const HeaderContainer = styled.div`
    ${FLEX_ROW}
    margin-bottom: 0.9375rem;
    font-family: Eina4-Bold;
    font-size: 1.4375rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    word-wrap: break-word;
`;

const BodyContainer = styled.div`
    ${FLEX_ROW}
    align-items: center;
    margin-top: 0.625rem;
`;

const StateListItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 28.75rem;
    margin-bottom: 0.9375rem;
    padding: 1.375rem;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #413424;
`;

const CasinoName = styled.div`
    width: 70%;
`;

const StateName = styled.div`
    text-align: right;
    width: 30%;
`;

const HallLocation = styled.div`
    font-family: Eina3-Regular;
    font-size: 1.0625rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    word-wrap: break-word;
    width: 70%;
`;

const GetDirection = styled.button`
    background: transparent;
    border: none;
    padding: 0;
    width: 7.3125rem;
    height: 1.875rem;
    background-image: url(${imgGetDirections});
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
`;

export const StateList: FC<StateListProps> = ({ states, ...props }) => {
    const onGetDirection = (hall: HallType) => {
        window.open(hall.googleMapURL, '_blank');
    };

    return (
        <StateListContainer {...props} images={imgGetDirections}>
            {states.map((item, index) => (
                <StateListItem key={index}>
                    <HeaderContainer>
                        <CasinoName>{item.locationName}</CasinoName>
                        <StateName>{item.state}</StateName>
                    </HeaderContainer>

                    {item.halls.map((hall, index) => (
                        <BodyContainer key={index}>
                            <HallLocation>{hall.location}</HallLocation>
                            <GetDirection
                                type="button"
                                onClick={() => onGetDirection(hall)}
                            />
                        </BodyContainer>
                    ))}
                </StateListItem>
            ))}
        </StateListContainer>
    );
};
