import { HTMLAttributes, FC } from 'react';
import styled, { css } from 'styled-components';

import { WaitImages } from '../WaitImages';

import imgBorderMain from '../../assets/images/page-container/border-main.png';
import imgBorderModal from '../../assets/images/page-container/border-modal.png';

export const IMAGES = {
    main: imgBorderMain,
    modal: imgBorderModal,
};

export type PageContainerPartialProps = {
    type: 'main' | 'modal';
};

export type PageContainerProps = HTMLAttributes<HTMLDivElement> &
    PageContainerPartialProps;

export const BORDERS = {
    main: css`
        background-image: url(${imgBorderMain});
        background-size: 2.5rem 79.1475rem;
        width: 2.5rem;
        transform: translateX(-1.25rem);
    `,
    modal: css`
        background-image: url(${imgBorderModal});
        background-size: 1rem 77.0625rem;
        width: 1rem;
        transform: translateX(-0.5rem);
    `,
};

export const CONTAINER_STYLE = {
    main: css`
        position: relative;
        min-height: 100%;
    `,
    modal: css`
        position: absolute;
        height: 100%;
    `,
};

export const Container = styled(WaitImages)<PageContainerPartialProps>`
    text-align: left;
    display: inline-block;
    max-width: 38.125rem;
    width: 100%;
    transition: opacity 0.5s linear;
    ${(props) => CONTAINER_STYLE[props.type]};
`;

export const ContentContainer = styled.div`
    width: 100%;
`;

export const Background = styled.div<PageContainerPartialProps>`
    background-image: linear-gradient(to top, #30241b, #5c3d1b);
    padding-bottom: 30px;
    position: relative;
    min-height: 1300px;
`;

export const BorderLeft = styled.div<PageContainerPartialProps>`
    z-index: 1;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: repeat-y;
    background-position: center center;
    ${(props) => BORDERS[props.type]}
`;

export const BorderRight = styled(BorderLeft)<PageContainerProps>`
    left: ${(props) => (props.type === 'main' ? '98%' : '100%')};
`;

export const PageContainer: FC<PageContainerProps> = ({
    children,
    type,
    ...props
}) => {
    return (
        <Container {...props} type={type} images={[IMAGES[type]]}>
            <Background type={type}>
                <BorderLeft type={type} />
                <BorderRight type={type} />
                <ContentContainer>{children}</ContentContainer>
            </Background>
        </Container>
    );
};
