import { useNavigate } from 'react-router-dom';

import { BigButton } from '../../components/BigButton';

export const WhereToPlayButton = () => {
    const navigate = useNavigate();

    return (
        <BigButton colorType="green" onClick={() => navigate('/redeem')}>
            Where To Play
        </BigButton>
    );
};
