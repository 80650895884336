import { useLayoutEffect, useState } from 'react';

export type Dimension = {
    width: number;
    height: number;
};

export default function useWaitImages(images: string[] | string, delay = 50) {
    const [isReady, setReady] = useState(false);
    const [dimensions, setDimensions] = useState<Dimension[]>([]);

    useLayoutEffect(() => {
        const urls = Array.isArray(images) ? images : [images];

        const promises = urls.map(
            (url) =>
                new Promise<Dimension | null>((resolve) => {
                    const img = new Image();
                    img.src = url;
                    img.onload = () => {
                        console.log(url, img.width, img.height);
                        resolve({
                            width: img.naturalWidth,
                            height: img.naturalWidth,
                        });
                    };

                    img.onerror = () => {
                        resolve(null);
                    };
                })
        );

        Promise.all(promises).then((dimensions) => {
            setDimensions(dimensions.filter((dim) => dim) as Dimension[]);
            setTimeout(setReady, delay, true);
        });
    }, [images, delay]);

    return [isReady, dimensions] as const;
}
