import { createState } from '@hookstate/core';

import { createStateRequest } from './loader';

import { getRedeemDetails } from '../lib/contentful';

import { LocationType } from '../types';

export type RedeemState = LocationType[];

export const state = createState([] as RedeemState);

export const fetchRedeemStates = createStateRequest(async () => {
    const locations = await getRedeemDetails();
    state.set(locations);
});
