import {
    createRef,
    FC,
    HTMLAttributes,
    KeyboardEvent,
    useEffect,
    useState,
} from 'react';
import styled from 'styled-components';

import { BigButton } from '../BigButton';

import { WaitImages } from '../WaitImages';

import imgReplayerBoxEmpty from '../../assets/images/replayer-entry/box-empty@2x.png';
import imgReplayerBoxHighlight from '../../assets/images/replayer-entry/box-highlight@2x.png';
import imgReplayerInfo from '../../assets/images/replayer-entry/info@2x.png';
import imgReplayerBg from '../../assets/images/replayer-entry/replayer-entry-bg@2x.png';

export type ReplayerEntryContainerProps = HTMLAttributes<HTMLDivElement> & {
    onEnter: (codes: string) => void;
    onInfoClick: () => void;
    entryCode?: string[];
};

export const ReplayerEntryContainer = styled(WaitImages)`
    position: relative;
    background-image: url(${imgReplayerBg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 35rem;
    height: 18.5rem;
    font-family: Eina4-SemiBold;
    text-align: center;
    margin: 0 auto;
    transition: opacity 0.5s linear;
`;

export const HeaderText = styled.div`
    position: relative;
    font-size: 1.5rem;
    text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
    color: #fff96b;
    top: 12.5%;
`;

export const InfoText = styled.div`
    position: relative;
    margin: 1rem 0;
    color: #501f04;
    font-size: 0.95625rem;
    text-shadow: 0 0.0625rem 0 rgba(255, 255, 255, 0.5);
    span {
        font-size: 1.1875rem !important;
    }
    top: 15%;
`;

export const InfoTextLabel = styled.div`
    position: relative;
    display: inline-block;
`;

export const EntryContainer = styled.form`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 28.125rem;
    top: 15%;
    left: 12%;
`;

export const InputsContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 13.125rem;
`;

export const InputContainer = styled.div`
    background-image: url(${imgReplayerBoxEmpty});
    background-size: contain;
    background-repeat: no-repeat;
    width: 3.1875rem;
    height: 3.5625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    & > input:valid {
        background-image: url(${imgReplayerBoxHighlight});
        background-size: 3.1875rem 3.5625rem;
        background-repeat: no-repeat;
        background-position: -0.6875rem -0.6875rem;
    }
`;

export const Input = styled.input`
    position: relative;
    border: transparent;
    background: transparent;
    border: none;
    width: 1.3125rem;
    height: 2.125rem;
    left: -0.1875rem;
    text-align: center;
    font-size: 1.1875rem;
    text-shadow: 0 0.125rem 0.1875rem #000;
    color: #fff25f;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const InfoIcon = styled.div`
    position: absolute;
    top: -0.5rem;
    right: -1.5rem;
    background-image: url(${imgReplayerInfo});
    background-size: contain;
    background-repeat: no-repeat;
    height: 1.125rem;
    width: 1.125rem;
    cursor: pointer;
    z-index: 5;
    &:active {
        transform: scale(0.9);
    }
`;

export const ActionContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 3rem;
    position: relative;
    top: 20%;
`;

const BG_IMAGES = [
    imgReplayerBg,
    imgReplayerBoxEmpty,
    imgReplayerBoxHighlight,
    imgReplayerInfo,
];

export const HomePageReplayerEntry: FC<ReplayerEntryContainerProps> = ({
    onEnter,
    onInfoClick,
    entryCode = [],
    ...props
}) => {
    const formRef = createRef<HTMLFormElement>();
    const [codes, setCodes] = useState<string[]>([]);

    useEffect(() => {
        if (formRef.current) {
            setCodes(entryCode);
            for (let i = 0; i < entryCode.length; i++) {
                (formRef.current[i] as HTMLInputElement).value = entryCode[i];
            }
        }
    }, [entryCode, formRef.current]);

    const onSubmit = () => {
        if (codes.slice(0).length > 7) {
            onEnter(codes.join(''));
        }
    };

    const onClear = () => {
        if (formRef.current) {
            formRef.current.reset();
            setCodes([]);
        }
    };

    const onCodeChange = (index: number, text: string) => {
        const trimmed = text.trim();
        const newCodes = codes.slice(0);
        if (trimmed !== '') {
            newCodes[index] = trimmed;
            setCodes(newCodes);
            if (formRef && formRef.current && formRef.current[index + 1]) {
                (formRef.current[index + 1] as HTMLFormElement).focus();
            }
        }
    };

    const onInputKeyDown = (
        index: number,
        e: KeyboardEvent<HTMLInputElement>
    ) => {
        if (formRef && formRef.current) {
            if (e.key === 'Backspace' || e.key === 'Delete') {
                setCodes((c) => c.filter((_val, i) => i !== index));
                if (formRef.current[index] && index !== 0) {
                    if (
                        (formRef.current[index] as HTMLFormElement).value !== ''
                    ) {
                        (formRef.current[index] as HTMLFormElement).focus();
                    } else {
                        (formRef.current[index - 1] as HTMLFormElement).focus();
                    }
                }
            }
        }
    };

    return (
        <ReplayerEntryContainer {...props} images={BG_IMAGES}>
            <HeaderText>DID YOU WIN?</HeaderText>
            <InfoText>
                <InfoTextLabel>
                    Enter the order ID found on your ticket below:
                    <InfoIcon onClick={onInfoClick} />
                </InfoTextLabel>
                <br />
                or open your camera to scan the QR code on your ticket
            </InfoText>
            <EntryContainer ref={formRef}>
                <InputsContainer>
                    <InputContainer>
                        <Input
                            onChange={(e) => onCodeChange(0, e.target.value)}
                            onKeyDown={(e) => onInputKeyDown(0, e)}
                            type="text"
                            maxLength={1}
                            required
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input
                            onChange={(e) => onCodeChange(1, e.target.value)}
                            onKeyDown={(e) => onInputKeyDown(1, e)}
                            type="text"
                            maxLength={1}
                            required
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input
                            onChange={(e) => onCodeChange(2, e.target.value)}
                            onKeyDown={(e) => onInputKeyDown(2, e)}
                            type="text"
                            maxLength={1}
                            required
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input
                            onChange={(e) => onCodeChange(3, e.target.value)}
                            onKeyDown={(e) => onInputKeyDown(3, e)}
                            type="text"
                            maxLength={1}
                            required
                        />
                    </InputContainer>
                </InputsContainer>
                <InputsContainer>
                    <InputContainer>
                        <Input
                            onChange={(e) => onCodeChange(4, e.target.value)}
                            onKeyDown={(e) => onInputKeyDown(4, e)}
                            type="text"
                            maxLength={1}
                            required
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input
                            onChange={(e) => onCodeChange(5, e.target.value)}
                            onKeyDown={(e) => onInputKeyDown(5, e)}
                            type="text"
                            maxLength={1}
                            required
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input
                            onChange={(e) => onCodeChange(6, e.target.value)}
                            onKeyDown={(e) => onInputKeyDown(6, e)}
                            type="text"
                            maxLength={1}
                            required
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input
                            onChange={(e) => onCodeChange(7, e.target.value)}
                            onKeyDown={(e) => onInputKeyDown(7, e)}
                            type="text"
                            maxLength={1}
                            required
                        />
                    </InputContainer>
                </InputsContainer>
            </EntryContainer>
            <ActionContainer>
                <BigButton colorType={'grey'} onClick={onClear}>
                    Clear
                </BigButton>
                <BigButton
                    colorType={codes.slice(0).length > 7 ? 'blue' : 'grey'}
                    onClick={onSubmit}
                >
                    Enter
                </BigButton>
            </ActionContainer>
        </ReplayerEntryContainer>
    );
};
