import { useEffect, useCallback, useState } from 'react';
import { HomePageHeader as HomePageHeaderComponent } from '../../components/HomePageHeader';
import { getNextGameDate } from '../../lib/api';
import useTimer from '../../hooks/use-timer';

export const HomePageHeader = () => {
    const [date, setDate] = useState('');

    const fetchNextGameDate = useCallback(async () => {
        const drawResp = await getNextGameDate();
        setDate(drawResp.gameTime);
    }, [setDate]);

    useEffect(() => {
        fetchNextGameDate();
    }, [fetchNextGameDate]);

    const { timeStamp, days, hours, minutes } = useTimer(
        date,
        fetchNextGameDate
    );

    return (
        <HomePageHeaderComponent
            label="NEXT GAME"
            timestamp={timeStamp}
            days={days}
            hours={hours}
            minutes={minutes}
        />
    );
};
