import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

    html, body {
        overflow-x: hidden;
    }

    @media (max-width: 630px) {
        html {
            font-size: calc(100vw * 16 / 630);
        }
    }
`;
