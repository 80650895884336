import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { CommonHeader } from '../CommonHeader';

import { WaitImages } from '../WaitImages';

import imgGameRecap from '../../assets/images/recap-header/game-recap@2x.png';

export type RecapHeaderProps = HTMLAttributes<HTMLDivElement> & {
    onClose: () => void;
};

export const RecapHeaderContainer = styled(CommonHeader)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RecapLogo = styled(WaitImages)`
    position: relative;
    background-image: url(${imgGameRecap});
    background-size: 100% 100%;
    width: 20.5625rem;
    height: 8.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -0.625rem;
`;

export const RecapLogoText = styled.div`
    text-shadow: 0 0.1875rem 0 rgba(0, 0, 0, 0.5);
    font-family: Eina4-SemiBold;
    font-size: 2.5rem;
    text-align: center;
    color: #fff96b;
    padding-top: 0.625rem;
`;

export const RecapHeader: FC<RecapHeaderProps> = ({ onClose, ...props }) => (
    <RecapHeaderContainer {...props} onClose={onClose}>
        <RecapLogo images={imgGameRecap}>
            <RecapLogoText>Game Recap</RecapLogoText>
        </RecapLogo>
    </RecapHeaderContainer>
);
