import { createState } from '@hookstate/core';
import { setError } from './error';

export type LoaderState = {
    main: number;
    pastDraws: number;
};

export type LoaderStateKeys = keyof LoaderState;

export const state = createState({
    main: 0,
    pastDraws: 0,
} as LoaderState);

const increase = (r: number) => r + 1;
const decrease = (r: number) => r - 1;

export const tryLoadingState = async <R>(
    fn: () => Promise<R>,
    key: LoaderStateKeys = 'main'
) => {
    state[key].set(increase);
    try {
        return await fn();
    } catch (error) {
        setError(error);
    } finally {
        state[key].set(decrease);
    }
};

export const createStateRequestWithParams =
    <A, R>(fn: (a: A) => Promise<R>, key?: LoaderStateKeys) =>
    (args: A) =>
        tryLoadingState(() => fn(args), key);

export const createStateRequest =
    <R>(fn: () => Promise<R>, key?: LoaderStateKeys) =>
    () =>
        tryLoadingState(() => fn(), key);
