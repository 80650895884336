import { useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import imgBackground from './assets/images/home-page/bg@2x.png';
import { PageContainer } from './components/PageContainer';
import { BurgerMenu } from './containers/BugerMenu';
import { ErrorPopup } from './containers/ErrorPopup';
import { GameListSlider } from './containers/GameListSlider';
import { HomePageHeader } from './containers/HomePageHeader';
import { HomePageReplayerEntry } from './containers/HomePageReplayerEntry';
import { Loader } from './containers/Loader';
import { PastDraws } from './containers/PastDraws';
import { WhereToPlayButton } from './containers/WhereToPlayButton';
import { initialize } from './lib/contentful';
import { fetchHomeDetails } from './stores/home';
import { LoaderStateKeys } from './stores/loader';

export const LayoutHeader = styled.div`
    background-image: url(${imgBackground});
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38.125rem;
    height: 8.25rem;
`;

export const MarginedGameListSlider = styled(GameListSlider)`
    margin-top: 0.625rem;
`;

export const WhereToPlayButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const LOADER_KEYS = ['main'] as LoaderStateKeys[];

export const Layout = () => {
    const navigation = useRef(useNavigate());

    useEffect(() => {
        initialize()
            .then(fetchHomeDetails)
            .then(() => {
                const params = new URLSearchParams(location.search.slice(1));
                const page = params.get('pathname');

                if (page) {
                    navigation.current({
                        hash: params.get('hash') || '',
                        pathname: decodeURIComponent(page || ''),
                        search: params.get('search') || '',
                    });
                }
            });
    }, []);

    return (
        <PageContainer type="main">
            <Outlet />
            <LayoutHeader>
                <BurgerMenu />
                <HomePageHeader />
            </LayoutHeader>
            <MarginedGameListSlider />
            <HomePageReplayerEntry />
            <PastDraws />
            <WhereToPlayButtonContainer>
                <WhereToPlayButton />
            </WhereToPlayButtonContainer>
            <ErrorPopup />
            <Loader loaderKeys={LOADER_KEYS} />
        </PageContainer>
    );
};
