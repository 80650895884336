import { FC, useState, HTMLAttributes } from 'react';
import styled, { keyframes } from 'styled-components';

import { PageContainer } from '../PageContainer';
import { RecapHeader } from '../RecapHeader';
import { RecapNav } from '../RecapNav';
import { RecapDetails, RecapDetailsProps } from '../RecapDetails';
import { CallBoard } from '../CallBoard';
import { WinningCard, WinningCardProps } from '../WinningCard';
import { SmallButton } from '../SmallButton';
import { BallCallBoard } from '../BallCallBoard';
import { BallProps } from '../Ball';

export type RecapProps = HTMLAttributes<HTMLDivElement> & {
    hasNavigation?: boolean;
    onClose: () => void;
    onPrevious: () => void;
    onNext: () => void;
    navLabel: string;
    previousDisabled?: boolean;
    nextDisabled?: boolean;
    recapPageDetails: RecapDetailsProps;
    recapPageBallCalls: BallProps[];
    callBoardCalled: number[];
    winningCards: WinningCardProps[];
    loading: boolean;
};

export const RecapMarginNav = styled(RecapNav)`
    margin: 0.625rem auto;
`;

export const RecapDetailsContainer = styled.div`
    background-color: #30241b;
    border-radius: 0.625rem;
    margin: 0 2.3125rem;
    padding: 1.25rem;
`;

export const RecapDetailContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

export const RecapMarginDetails = styled(RecapDetails)`
    margin: 0 auto;
`;

export const RecapCallBoardContainer = styled.div`
    height: 15.625rem;
    width: 33.125rem;
    position: relative;
    left: -1.0625rem;
    overflow: hidden;
    transition: 300ms all;
`;

export const fromLeft = keyframes`
    0% {
        left: -62.5rem;
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        left: 0;
        opacity: 1;
    }
`;

export const fromRight = keyframes`
    0% {
        right: -62.5rem;
        opacity: 0;

    }
    50% {
        opacity: 0.5;
    }
    100% {
        right: 0px;
        opacity: 1;

    }
`;

export const RecapCallBoard = styled(CallBoard)`
    position: relative;
    transform: scale(0.41);
    animation-name: ${fromRight};
    animation-duration: 300ms;
    animation-direction: forwards;
`;

export const WinningCardsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    & > div {
        margin: 0 auto;
    }
`;

export const BoardButtonSwitchContainer = styled.div`
    position: relative;
    top: 2.5rem;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1.25rem;
`;

export const RecapBallCallBoard = styled(BallCallBoard)`
    position: relative;
    animation-name: ${fromLeft};
    animation-duration: 300ms;
    animation-direction: forwards;
    width: 100%;
`;

export const RecapWinningCard = styled(WinningCard)`
    padding-bottom: 5.625rem;
`;

export const Recap: FC<RecapProps> = ({
    hasNavigation = true,
    winningCards,
    callBoardCalled,
    recapPageBallCalls,
    recapPageDetails,
    navLabel,
    onPrevious,
    onNext,
    onClose,
    loading,
    previousDisabled,
    nextDisabled,
    children,
    ...props
}) => {
    const [selectedButton, setSelectedButton] = useState(0);

    return (
        <PageContainer {...props} type="modal">
            <RecapHeader onClose={onClose} />
            <RecapMarginNav
                onPrevious={onPrevious}
                label={navLabel}
                onNext={onNext}
                previousDisabled={loading || previousDisabled || !hasNavigation}
                nextDisabled={loading || nextDisabled || !hasNavigation}
            />
            <RecapDetailsContainer>
                <RecapDetailContent>
                    <RecapMarginDetails {...recapPageDetails} />
                </RecapDetailContent>
                <BoardButtonSwitchContainer>
                    <SmallButton
                        buttonType={selectedButton == 0 ? 'light' : 'dark'}
                        buttonLabel={'CALL BOARD'}
                        onClick={() => setSelectedButton(0)}
                    />
                    <SmallButton
                        buttonType={selectedButton == 1 ? 'light' : 'dark'}
                        buttonLabel={'BALLS CALLED'}
                        onClick={() => setSelectedButton(1)}
                    />
                </BoardButtonSwitchContainer>
                <RecapCallBoardContainer>
                    {selectedButton == 0 ? (
                        <RecapCallBoard called={callBoardCalled} />
                    ) : (
                        <RecapBallCallBoard ballCalls={recapPageBallCalls} />
                    )}
                </RecapCallBoardContainer>
                <WinningCardsContainer>
                    {winningCards.map((card, i) => (
                        <RecapWinningCard key={i} {...card} />
                    ))}
                </WinningCardsContainer>
            </RecapDetailsContainer>
            {children}
        </PageContainer>
    );
};
