import { HTMLAttributes, FC } from 'react';
import styled from 'styled-components';

import { WaitImages } from '../WaitImages';

import imgBtnMenu from '../../assets/images/burger-menu/btn-menu@2x.png';

export type BurgerMenuProps = HTMLAttributes<HTMLButtonElement>;

export const MenuContainer = styled(WaitImages)`
    display: inline-block;
`;

export const MenuButton = styled.button`
    background-image: url(${imgBtnMenu});
    background-color: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 5.625rem;
    width: 5.625rem;
    cursor: pointer;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    &:active {
        transform: scale(0.9);
    }
`;

export const BurgerMenu: FC<BurgerMenuProps> = ({ style, ...props }) => (
    <MenuContainer style={style} images={imgBtnMenu}>
        <MenuButton type="button" {...props} />
    </MenuContainer>
);
