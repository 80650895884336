import { createState } from '@hookstate/core';
import { PastDraw, RecapDetails } from '../types';
import { getPastDraws, getRecapDetails } from '../lib/api';
import { createStateRequest, createStateRequestWithParams } from './loader';

export type RecapState = {
    pastDraws: PastDraw[];
    cursor: number;
    current?: RecapDetails;
    requests: number;
};

export const getLatestDateTime = () => new Date().toISOString();

export const PAGE_SIZE = 5;

export const state = createState({
    requests: 0,
    pastDraws: [],
    cursor: -1,
} as RecapState);

export const fetchLatestPastDraws = createStateRequest(async () => {
    const pastDraws = await getPastDraws({
        size: PAGE_SIZE,
        page: 1,
    });

    state.pastDraws.set(pastDraws);
});

export const fetchNextPastDraws = createStateRequest(async () => {
    const pastDraws = state.pastDraws.value;

    if (pastDraws.length === 0) {
        return;
    }

    const last = pastDraws[pastDraws.length - 1];

    const nextPastDraws = await getPastDraws({
        size: PAGE_SIZE,
        page: 1,
        fromDateTime: last.gameDate,
    });

    state.pastDraws.set((pastDraws) => pastDraws.concat(nextPastDraws));
});

export const fetchRecapDetails = createStateRequestWithParams(
    async (argsCursor: number) => {
        const cursor = Math.max(argsCursor, 0);
        const pastDraws = state.pastDraws.value;
        const isFirst = cursor === 0;
        const isLast = cursor >= pastDraws.length - 1;
        let current = pastDraws[cursor];

        if (isFirst) {
            await fetchLatestPastDraws();
        } else if (isLast) {
            await fetchNextPastDraws();
        }

        if (isFirst || isLast) {
            const newCursor = Math.max(
                state.pastDraws.value.findIndex(
                    (draw) => draw.gameSessionId === current?.gameSessionId
                ),
                0
            );

            state.cursor.set(newCursor);
            current = state.pastDraws.value[newCursor];
        } else {
            state.cursor.set(cursor);
        }

        const recapDetails = await getRecapDetails(current.gameSessionId);
        state.current.set(recapDetails);
    }
);

export const fetchRecapDetail = createStateRequestWithParams(
    async (gameSessionId: string) => {
        const details = await getRecapDetails(gameSessionId);
        state.current.set(details);
    }
);

export const fetchPreviousRecapDetails = () =>
    fetchRecapDetails(state.cursor.value + 1);

export const fetchNextRecapDetails = () =>
    fetchRecapDetails(state.cursor.value - 1);
