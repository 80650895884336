import { HTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import { CommonCloseButton } from '../CommonCloseButton';

import { WaitImages } from '../WaitImages';

import imgHeaderBg from '../../assets/images/about-page-header/header-bg@2x.png';

export type CommonHeaderProps = HTMLAttributes<HTMLDivElement> & {
    onClose: () => void;
};

export const HeaderContainer = styled(WaitImages)`
    position: relative;
    background-image: url(${imgHeaderBg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 11.875rem;
    width: 38.0625rem;
    margin: 0 auto;
`;

export const CommonHeaderCloseBtn = styled(CommonCloseButton)`
    position: absolute;
    right: 5%;
    top: 30%;
`;

export const CommonHeader: FC<CommonHeaderProps> = ({
    onClose,
    children,
    ...props
}) => (
    <HeaderContainer {...props} images={imgHeaderBg}>
        {children}
        <CommonHeaderCloseBtn onClose={onClose} />
    </HeaderContainer>
);
