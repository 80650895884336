import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { WaitImages } from '../WaitImages';

import imgHeaderBg from '../../assets/images/home-page-header/header-bg@2x.png';
import imgHeaderBlue from '../../assets/images/home-page-header/blue@2x.png';

export type HomePageHeaderProps = HTMLAttributes<HTMLDivElement> & {
    label: string;
    timestamp: string;
    days: string;
    hours: string;
    minutes: string;
};

export const HomePageHeaderContainer = styled(WaitImages)`
    background-image: url(${imgHeaderBg});
    background-size: contain;
    background-repeat: no-repeat;
    font-family: Eina4-SemiBold;
    width: 28.875rem;
    height: 6.25rem;
    color: #fff;
    position: relative;
    text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
`;

export const MainText = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff96b;
    position: absolute;
    top: 19%;
    left: 8%;
`;

export const TimeStamp = styled.div`
    position: absolute;
    font-size: 0.8125rem;
    top: 50%;
    left: 8%;
`;

export const BlueCircleContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 18.75rem;
    height: 6.25rem;
    left: 42%;
    top: 0.3125rem;
    margin: 0;
`;

export const BlueCircle = styled.div`
    position: relative;
    background-image: url(${imgHeaderBlue});
    background-size: cover;
    background-repeat: no-repeat;
    height: 5rem;
    width: 5rem;
    text-align: center;
`;

export const BlueCircleDetails = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    justify-content: center;
    font-size: 1.875rem;
    align-items: center;
    margin-top: 0.9375rem;
    span {
        font-size: 0.625rem;
        color: #03dae9;
    }
`;

const BG_IMAGES = [imgHeaderBg, imgHeaderBlue];

export const HomePageHeader: FC<HomePageHeaderProps> = ({
    label,
    timestamp,
    days,
    hours,
    minutes,
    ...props
}) => (
    <HomePageHeaderContainer {...props} images={BG_IMAGES}>
        <MainText>{label}</MainText>
        <TimeStamp>{timestamp}</TimeStamp>
        <BlueCircleContainer>
            <BlueCircle>
                <BlueCircleDetails>
                    <div>{days}</div>
                    <span>Days</span>
                </BlueCircleDetails>
            </BlueCircle>
            <BlueCircle>
                <BlueCircleDetails>
                    <div>{hours}</div>
                    <span>Hours</span>
                </BlueCircleDetails>
            </BlueCircle>
            <BlueCircle>
                <BlueCircleDetails>
                    <div>{minutes}</div>
                    <span>Minutes</span>
                </BlueCircleDetails>
            </BlueCircle>
        </BlueCircleContainer>
    </HomePageHeaderContainer>
);
