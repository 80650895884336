import { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { WaitImages } from '../WaitImages';

import imgCallBoardSquare from '../../assets/images/callboard/square.png';

export type CallBoardSquarePartialProps = {
    called?: boolean;
};

export type CallBoardSquareProps = HTMLAttributes<HTMLDivElement> &
    CallBoardSquarePartialProps;

export const CSS_CALLED = css`
    color: white;
    &:before {
        opacity: 0.3;
    }
`;

export const CSS_DEFAULT = css`
    color: #b98c38;
`;

export const CALLBOARD_SQUARE_SIZE = '4.46875rem';

export const CallBoardSquareContainer = styled(
    WaitImages
)<CallBoardSquarePartialProps>`
    font-weight: bold;
    font-size: 2.5rem;
    font-family: Roboto;
    width: ${CALLBOARD_SQUARE_SIZE};
    height: ${CALLBOARD_SQUARE_SIZE};
    line-height: ${CALLBOARD_SQUARE_SIZE};
    text-align: center;
    position: relative;
    user-select: none;
    z-index: 1;
    display: inline-block;

    &:before {
        content: '';
        width: ${CALLBOARD_SQUARE_SIZE};
        height: ${CALLBOARD_SQUARE_SIZE};
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(${imgCallBoardSquare});
        background-repeat: no-repeat;
        background-position: center center;
        z-index: -1;
    }
    ${(props) => (props.called ? CSS_CALLED : CSS_DEFAULT)};
`;

export const CallBoardSquare: FC<CallBoardSquareProps> = (props) => {
    return <CallBoardSquareContainer {...props} images={imgCallBoardSquare} />;
};
