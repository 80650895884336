import { ComponentProps, HTMLAttributes, FC } from 'react';
import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';

export type LoaderProps = HTMLAttributes<HTMLDivElement> &
    ComponentProps<typeof TailSpin>;

export const LoaderContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
`;

export const Loader: FC<LoaderProps> = ({
    height = 80,
    width = 80,
    color = '#fffb9e',
    wrapperStyle,
    wrapperClass,
    visible,
    radius,
    ...props
}) => (
    <LoaderContainer {...props}>
        <TailSpin
            width={width}
            height={height}
            color={color}
            wrapperStyle={wrapperStyle}
            wrapperClass={wrapperClass}
            visible={visible}
            radius={radius}
        />
    </LoaderContainer>
);
