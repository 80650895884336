import { useState } from '@hookstate/core';

import { GameListSlider as GameListSliderComponent } from '../../components/GameListSlider';
import { state } from '../../stores/home';

export const GameListSlider = () => {
    const { banners } = useState(state);

    return <GameListSliderComponent slides={banners.value} />;
};
